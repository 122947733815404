<template>
  <div :class="$style[classes]">
    <validate-form tag="div" ref="form">
      <pt-form-item>
        <div :class="$style['form-item-box']">
          <span>任务单编号：</span>
          <p>
            {{$route.query.serialNumber}}
          </p>
        </div>
      </pt-form-item>
      <pt-form-item>
        <div :class="$style['form-item-box']">
          <span>原实验室人员：</span>
          <p>
            {{$route.query.labTesterName}}
          </p>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="新实验室人员"
        rules="required"
        ref="username"
      >
        <div :class="[$style['form-item-box'],$style['required']]">
          <span>新实验室人员：</span>
          <cube-select
            v-model="formValue.labTester"
            :options="options">
          </cube-select>
        </div>
      </pt-form-item>
    </validate-form>
    <div :class="$style['btn-box']">
      <wt-button :class="$style['submit-btn']" @click="submit" primary>确认改派</wt-button>
    </div>
  </div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-task-reassign'

export default {
  name: 'pt-task-reassign',
  computed: {
    classes () {
      return `${prefixCls}`
    }
  },
  data () {
    return {
      formValue: {},
      options:[],
      // authorizationCode:null
    };
  },
  methods:{
    getOptions () {
      let data = {
        role:["ROLE_RT_EX", "ROLE_EX"],
        pageable: {
          page: 0,
          size: 99999
        }
      }
      this.$store.dispatch('systemCustomerList', data).then(data => {

        this.options = data.map(ele => {
          return {
            text: ele.firstName,
            value: JSON.stringify(ele)
          }
        })
      })
    },
    submit () {
      this.$refs.form.validate("formValue").then((valid) => {
        if(!valid){
          return
        }
        this.$loading()
        const {id, firstName,signatureImageUrl} = JSON.parse(this.formValue.labTester)
        let data = {
          billId:this.$route.params.billId,
          ids:[this.$route.params.id],
          newLabTester:{
            name:firstName,
						organizationUserId:id,
						signatureImageUrl:signatureImageUrl
          }
        }
        this.$store.dispatch('taskReassgin', data).then(res => {
          this.$toast.success('操作成功')
          this.$router.go(-1)
        }).catch(err => {
          this.$toast.error(err.message || err)
        }).finally(()=>{
          this.$loading.hide()
        })
      });
      
    
    },
  },
  created(){
    this.getOptions()
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-task-reassign{
  > div:first-child{
    background-color: #fff;
    padding:@page-padding 25px;
    >div{
      &:last-child{
        .form-item-box{
          border-bottom: none;
        }
      }
    }
  }
  .btn-box{
    padding: 20px @page-padding;
    :global{
      .cube-btn{
        width: 100%;
      }
    }
  }
}
.form-item-box{
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid @page-background-color;
  &.required{
    > span{
      position: relative;
      &::before{
        content: '*';
        position: absolute;
        color:red;
        left: -10px;
        top: 0;
        width: auto;
      }
    }
  }
  > span{
    white-space: nowrap;
    width: 120px;
    text-align: left;
  }
  > p{
    padding: 10px;
  }
  :global{
    .wt-input,
    .cube-select{
      flex: 1;
      text-align: left;
      &::after{
        border: none;
      }
    }
  }
}
</style>
