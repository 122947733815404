<template>
  <pt-task-reassign class="pt-task-reassign__page"></pt-task-reassign>
</template>

<script>
import PtTaskReassign from 'cps//pt-task-reassign/withData'
export default {
  name: 'pt-task-reassign__view',
  components: {
    'pt-task-reassign': PtTaskReassign
  }
}
</script>
