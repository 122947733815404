<template>
  <pt-task-reassign class="pt-task-reassign__withData"></pt-task-reassign>
</template>

<script>
import PtTaskReassign from './index'
export default {
  name: 'pt-task-reassign__withData',
  components: {
    'pt-task-reassign': PtTaskReassign
  }
}
</script>
